body {
  position: relative;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: initial;
  background-color: #f5f6fa;
}

// Disable transitions on first run.
body.rui-no-transition * {
  // stylelint-disable-next-line
  transition: none !important;
}

// Simple page content
.rui-main {
  overflow: hidden;
}

// Main Content
.rui-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .rui-navbar-top ~ & {
    min-height: calc(100vh - 70px);
  }
  .rui-footer {
    margin-top: auto;
  }
  .rui-page-title {
    position: relative;
    display: block;
    padding: 15px 15px;

    @include media-breakpoint-down(xs) {
      padding: 15px 5px;
      padding-top: 40px;
    }

    nav {
      + h1,
      + .h1 {
        margin-top: 5px;
        font-size: 1.6rem;
      }
    }
    h1,
    .h1 {
      margin-bottom: -10px;
    }
  }
  .rui-page-content {
    position: relative;
    display: block;
    padding: 30px 15px;
    z-index: 1;

    @include media-breakpoint-down(xs) {
      padding: 30px 5px;
    }
  }

  // Sidebar
  .rui-page-sidebar-sticky {
    position: sticky;
    top: 0;
    will-change: top;
    transition: top 0.15s ease-in-out;

    &.rui-scrollbar .os-scrollbar {
      padding-right: 10px;

      &.os-scrollbar-vertical {
        width: 16px;
      }
      &.os-scrollbar-horizontal {
        height: 8px;
      }
    }
    .rui-page-sidebar {
      padding-left: 15px;
      margin-left: -15px;
    }
  }
  .rui-page-sidebar {
    height: auto;
    max-height: 100vh;
    border-left: 1px solid $color_separator;

    > .nav {
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      padding-bottom: 15px;
      margin: 0;
      font-size: 13px;
      background-color: #fff;
    }
  }

  // React App.
  &.rui-page-react {
    min-height: auto;

    > div {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }

    .rui-navbar-top ~ & {
      min-height: auto;

      > div {
        min-height: calc(100vh - 70px);
      }
    }
  }
}

// Remove some paddings on mobile.
@include media-breakpoint-down(sm) {
  body:not(.rui-section-lines) {
    &.yay-hide .yaybar.yay-hide-to-small .yaybar-wrap > ul {
      padding-top: 0;
    }
    .yaybar.yay-hide-to-small ~ .rui-navbar-mobile {
      left: 0;

      .rui-navbar-head {
        transition: padding-left 0.15s ease-in-out;
      }

      &:not(.rui-navbar-show) .rui-navbar-head {
        padding-left: 0;
      }
    }
    .yaybar.yay-hide-to-small ~ .content-wrap {
      .rui-page-title {
        padding-top: 5px;
        padding-left: 0;

        > .container,
        > .container-fluid {
          padding-left: 0;
        }
      }
      .rui-page-content {
        padding-left: 0;

        > .container,
        > .container-fluid {
          padding-left: 0;
        }
      }
      .rui-footer,
      .rui-footer .container,
      .rui-footer .container-fluid {
        padding-left: 0;
      }
    }
  }
}

// Autohide
.rui-navbar-autohide {
  .rui-page-sidebar-sticky {
    margin-top: -30px;
    transform: translateY(0);
  }
  &.rui-navbar-show .rui-page-sidebar-sticky {
    top: 70px;
  }
}

// Separetor
.rui-separator {
  display: block;
  border-bottom: 1px solid $color_separator;
}

// Object Fit background
.bg-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-position: 50% 50%;
  background-size: cover;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    // stylelint-disable-next-line
    font-family: "object-fit: cover";
    object-fit: cover;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50% 50%;
    background-size: cover;
    z-index: 1;
  }
}

// gaps
.rui-gap,
.rui-gap-1,
.rui-gap-2,
.rui-gap-3,
.rui-gap-4,
.rui-gap-5,
.rui-gap-6 {
  display: block;
  height: 15px;
}
.rui-gap-1 {
  height: 25px;
}
.rui-gap-2 {
  height: 30px;
}
.rui-gap-3 {
  height: 40px;
}
.rui-gap-4 {
  height: 60px;
}
.rui-gap-5 {
  height: 80px;
}
.rui-gap-6 {
  height: 100px;
}

// body scrollbar checker
.rui-body-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Gmap
.rui-gmap {
  min-height: 400px;
  border-radius: 0.25rem;
}
