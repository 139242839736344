.rui-profile {
    // Img
    .rui-profile-img {
        display: block;
        max-width: 100px;
        overflow: hidden;
        border-radius: 100%;

        img {
            width: 100%;
            height: 100%;
            // stylelint-disable-next-line
            font-family: "object-fit: cover";
            object-fit: cover;
        }
    }
    // Info
    .rui-profile-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .rui-profile-info-title {
            margin-bottom: 0;

            + .rui-profile-info-subtitle {
                margin-top: 2px;
            }
        }
        .rui-profile-info-subtitle {
            margin-bottom: 0;
            font-size: 12px;
            color: $color_grey_5;

            + .rui-profile-info-mail,
            + .rui-profile-info-phone {
                margin-top: 12px;
            }
        }
        .rui-profile-info-mail,
        .rui-profile-info-phone {
            display: block;
            color: $color_text_2;

            &:hover {
                color: $color_text_1;
                text-decoration: none;
            }
        }
        .rui-profile-info-mail + .rui-profile-info-phone {
            margin-top: 3px;
        }
    }
    // Numbers
    .rui-profile-numbers {
        margin: 25px -30px 0 -30px;
        border-top: 1px solid $color_separator;
    }
    .rui-profile-number {
        padding: 25px 0 0;

        .rui-profile-number-title {
            margin-bottom: 0;
            color: $color_text_1;
        }
    }
    // List Activity
    .rui-profile-activity-list {
        > li {
            padding: 20px 0;

            &:first-child {
                padding-top: 10px;
            }
            &:last-child {
                padding-bottom: 5px;
            }
        }
    }
    // List Task
    .rui-profile-task-list {
        margin: 30px -30px -25px;

        > li {
            padding: 0;
        }
    }
}
