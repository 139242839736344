// Activity
.rui-project-activity-list {
    padding: 0;
    margin: 0 -30px -30px;

    .media.media-filled > .media-link {
        padding-top: 20.5px;
        padding-bottom: 20.5px;
    }

    > li,
    > li.list-group-item {
        padding: 0;

        > .rui-task-list {
            padding-left: 26px;

            > li {
                padding: 20px 0;
                border: 0;

                + li {
                    padding-top: 0;
                }
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
}
// Task
.rui-project-task-search {
    padding: 12px 30px 13px;
    margin: 0 -30px;
    border-top: 1px solid $color_separator;
}
.rui-project-task-info {
    display: flex;
    padding: 15px 30px;
    margin: 0 -30px;
    background-color: $color_grey_1;
    border-top: 1px solid $color_separator;
}
.rui-project-task-info-link {
    display: flex;
    margin-right: 20px;
    color: $color_secondary;

    &:hover,
    &.hover {
        color: $color_text_1;
        text-decoration: none;

        > .rui-project-task-info-icon {
            color: $color_text_1;
        }
    }
}
.rui-project-task-info-icon {
    margin-right: 9px;
    color: $color_grey_5;
    transition: color .15s ease-in-out;
}
.rui-project-task-info-title {
    display: block;
    margin-top: 1px;
}
.rui-project-task-list {
    margin: 0 -30px -30px;

    > li,
    > li.list-group-item {
        padding: 0;
    }
}
// Releases
.rui-project-releases-list {
    margin: 0 -30px -30px;

    > li,
    > li.list-group-item {
        padding: 30px;
    }
}
// Settings
.rui-project-settings-list {
    margin: 0 -30px -30px;

    > li,
    > li.list-group-item {
        padding: 30px;
    }

    // Checkboxes, Radios, Releases
    .rui-project-settings-list {
        margin: 0;

        > li {
            padding: 4px 0;

            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
